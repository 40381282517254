import React, { useState } from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AddFolderForm from './AddFolderForm';
import AddFileForm from './AddFileForm';
import { setSelectedNode } from '../redux/actions/documentActions';
import { useDispatch, useSelector } from 'react-redux';

const Sidebar = ({ children }) => {
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = (type) => {
    dispatch(setSelectedNode(null));
    if (type === 'folder') {
      setIsFolderModalOpen(true);
    } else if (type === 'file') {
      setIsFileModalOpen(true);
    }
  };

  const handleCloseFolderModal = () => setIsFolderModalOpen(false);
  const handleCloseFileModal = () => setIsFileModalOpen(false);

  const handleFolderSubmit = (folderName) => {
    handleCloseFolderModal();
  };
  const handleFileSubmit = ({ file, fileName }) => {
    handleCloseFileModal();
  };

  // const { uploadProgress } = useSelector((state) => state.documents);
  
  return (
    <Box
      sx={{
        width: '100%',
        boxShadow: 2,
        borderRadius: 2,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          borderBottom: '1px solid #d5d5d5',
          p: 1,
          px: 2,
          color: '#333',
        }}
      >
        Create Document
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          p: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            border: '1px solid #d5d5d5',
            borderRadius: '8px',
            backgroundColor: '#fff',
            p: 1.1,
            textAlign: 'center',
            cursor: 'pointer'
          }}
          onClick={() => handleOpenModal('folder')}
        >
          <Tooltip title="Create Folder">
            <IconButton size="small">
              <AddCircleOutlineIcon sx={{ color: '#90caf9', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          <Typography sx={{ fontWeight: 600, fontSize: '13px' }}>
            Create Folder
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            border: '1px solid #d5d5d5',
            borderRadius: '8px',
            backgroundColor: '#fff',
            p: 1.1,
            textAlign: 'center',
            cursor: 'pointer'
          }}
          onClick={() => handleOpenModal('file')}
        >
          <Tooltip title="Create File">
            <IconButton size="small">
              <InsertPhotoIcon sx={{ color: '#90caf9', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          <Typography sx={{ fontWeight: 600, fontSize: '13px' }}>
            Create File
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          // p: 2,
        }}
      >
        {children}
      </Box>

      <AddFolderForm 
        open={isFolderModalOpen} 
        onClose={handleCloseFolderModal} 
        onFolderAdded={handleFolderSubmit} 
      />
      <AddFileForm 
        open={isFileModalOpen} 
        onClose={handleCloseFileModal} 
        onSubmit={handleFileSubmit} 
      />
    </Box>
  );
};

export default Sidebar;
