// Action Types
export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

export const FETCH_DOC_PREVIEW_REQUEST = 'FETCH_DOC_PREVIEW_REQUEST';
export const FETCH_DOC_PREVIEW_SUCCESS = 'FETCH_DOC_PREVIEW_SUCCESS';
export const FETCH_DOC_PREVIEW_FAILURE = 'FETCH_DOC_PREVIEW_FAILURE';

export const RENAME_DOC_REQUEST = 'RENAME_DOC_REQUEST';
export const RENAME_DOC_SUCCESS = 'RENAME_DOC_SUCCESS';
export const RENAME_DOC_FAILURE = 'RENAME_DOC_FAILURE';

export const SAVE_DOC_REQUEST = 'SAVE_DOC_REQUEST';
export const SAVE_DOC_SUCCESS = 'SAVE_DOC_SUCCESS';
export const SAVE_DOC_FAILURE = 'SAVE_DOC_FAILURE';

export const SELECTED_NODE = 'SELECTED_NODE';
export const OBJECT_SELECTED_NODE = 'OBJECT_SELECTED_NODE';
export const UPLOAD_DOCUMENT_PROGRESS = 'UPLOAD_DOCUMENT_PROGRESS';


export const ZERO_LEVEL_DATA = 'ZERO_LEVEL_DATA' 
export const ZERO_LEVEL_DATA_REQUEST = 'ZERO_LEVEL_DATA_REQUEST'
export const ZERO_LEVEL_DATA_SUCCESS = 'ZERO_LEVEL_DATA_SUCCESS'
export const ZERO_LEVEL_DATA_FAILURE ='ZERO_LEVEL_DATA_FAILURE'

