import React, { useState } from 'react';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Typography, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { saveDocument } from '../redux/actions/documentActions'; // Adjust the path to where your API actions are defined

const AddFolderForm = ({ open, onClose, onFolderAdded }) => {
  const [folderName, setFolderName] = useState('');
  const [selectedValue, setSelectedValue] = useState('Client');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (!folderName.trim()) {
      setError('Folder name is required');
      return;
    }
    
    setLoading(true);
    setError('');

    const data = {
      category: selectedValue,
      dtype: "folder",
      location: "63ac458916769ea5183d3441",
      comments: "",
      pathUrl: "",
      fileKey: "",
      parent: selectedNode || '', 
      name: folderName,
      createdBy: "664636717bb44211e4022216" 
    };

    try {
      await dispatch(saveDocument(data));
      setFolderName('');
      setSelectedValue('Client');
      onFolderAdded();
      onClose();
    } catch (error) {
      setError('Failed to create folder. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  const selectedNode = useSelector((state) => state.documents.selectedNode);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Folder</DialogTitle>
      <DialogContent>
      {selectedNode && <Typography variant="body1" sx={{ mt: 2, fontSize: '12px' }}>
        {`ID: ${selectedNode}` }
      </Typography>}
        <Box>
          <Select
            labelId="user-role-select-label"
            id="user-role-select"
            value={selectedValue} 
            onChange={(e) => setSelectedValue(e.target.value)}
          >
            <MenuItem value="Client">Client</MenuItem>
            <MenuItem value="Care Manager">Care Manager</MenuItem>
            <MenuItem value="Referral Partner">Referral Partner</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
          </Select>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            variant="outlined"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            error={!!error}
            helperText={error}
          />
          {loading && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFolderForm;
