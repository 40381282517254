import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocuments, showFolderList } from '../redux/actions/documentActions';
import NestedItems from './NestedItems';
import buildHierarchy from '../redux/actions/buildHierarchy';
import { Box } from '@mui/material';

const DocumentList = () => {
  const dispatch = useDispatch();
  const [itemsArray, setItemsArray] = useState([]);
  
  const { fetchDocumentsLoading, error, documents } = useSelector((state) => state.documents);

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (!fetchDocumentsLoading && documents.length > 0) {
      const hierarchy = buildHierarchy(documents);
      setItemsArray(hierarchy);
      dispatch(showFolderList(hierarchy));
    }
  }, [fetchDocumentsLoading, documents, dispatch]);

  
  if (fetchDocumentsLoading) return <Box p={1}>Loading...</Box>;
  if (error) return <Box p={1}>Error: {error.message || 'An unexpected error occurred'}</Box>;
  if (documents.length === 0) return <Box p={1}>No documents available</Box>;

  return (
    <Box p={1}>
      <NestedItems items={itemsArray} />
    </Box>
  );
};

export default DocumentList;
