import "./App.css";
import Container from "@mui/material/Container";
import DocumentList from "./components/DocumentList";
import { Box } from "@mui/material";
import Sidebar from "./components/Sidebar";
import DocumentViewer from "./components/DocumentViewer";
import FolderViewer from './components/FolderViewer';

function App() {
  return (
    <div className="App">
      <Container style={{  justifyContent: "center" }}>
        <Box style={{display: 'flex', flexDirection: 'row', gap: '15px'}}>
          <Box 
              sx={{ 
                width: '300px',
                flexShrink: 0
              }}
            >
          <Sidebar>
            <DocumentList></DocumentList>
          </Sidebar>
          </Box>
          <Box sx={{ flexShrink: 1 , width:'100%', height: 'calc(100vh - 40px)', overflowY: 'auto',}}>
              <FolderViewer />
              <DocumentViewer />
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default App;
