const buildHierarchy = (list) => {
  const map = {};
  const roots = [];

  list?.forEach((item) => {
    map[item._id] = { ...item, children: [] };
  });

  list?.forEach((item) => {
    if (item.parent) {
      if (map[item.parent]) {
        map[item.parent].children.push(map[item._id]);
      }
    } else {
      roots.push(map[item._id]);
    }
  });

  return roots;
};

export default buildHierarchy;
