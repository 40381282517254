import axios from "axios";
import config from "../config";

const apiClient = axios.create({
  baseURL: config.API_BASE_URL,
});

export async function getSignedUrl({ key, content_type }) {
  const response = await apiClient.post("/s3/signed_url", {
    key,
    content_type,
  });
  return response.data;
}
export async function uploadFileToSignedUrl(signedUrl, file, contentType, onProgress) {
  try {
    const response = await axios.put(signedUrl, file, {
      onUploadProgress: onProgress,
      headers: {
        "Content-Type": contentType,
      },
    });
    return response; 
  } catch (err) {
    console.error(err.response);
    throw err; 
  }
}

export async function deleteImage(key,type,dbid) {
  const response = await apiClient.post("/s3/delete", {
    key,type,dbid
  });
  return response;
}
export async function previewDocument({ key }) {
  const response = await apiClient.post("/s3/preview", {
    key
  });
  return response;
}
export async function renameDocument(id, name) {
  const response = await apiClient.post("/s3/rename", {
    id, name
  });
  return response;
}
export async function getDocumets() {
  const response = await apiClient.get("/s3/getDocuments", {
    locationId:"",
    status: true,
  });

  return response;
}
export async function saveDocument(data) {
  
  const response = await apiClient.post("/s3/save", { 
    category:data.category,
    dtype:data.dtype,
    name:data.name,
    location:data.location,
    comments:data.comments,
    pathUrl:data.pathUrl,
    fileKey:data.fileKey,
    parent:data.parent,
    createdBy:data.createdBy 
  });
  return response;
}